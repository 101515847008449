export const statNames = {
  fga: 'FGA',
  fgm: 'FGM',
  fta: 'FTA',
  ftm: 'FTM',
  threepa: '3PA',
  threepm: '3PM',
  oreb: 'OREB',
  dreb: 'DREB',
  treb: 'REB',
  ast: 'AST',
  stl: 'STL',
  blk: 'BLK',
  tov: 'TOV',
  pf: 'PF',
  pts: 'PTS',
  tovPerc: 'TOV%',
  threePerc: '3PT%',
  usageRate: 'Usage Rate',
  aPER: 'aPER',
  fgPerc: 'FG%',
  efgPerc: 'eFG%',
  ortg: 'ORTG',
  drtg: 'DRTG',
  astToRatio: 'AST/TO Ratio',
  PER: 'PER',
  pace: 'Pace',
  threepAR: '3PT Attempt %',
  gameScore: 'Game Score',
  gp: 'Games Played'
};

export const basicStats = [
  'pts',
  'treb',
  'ast',
  'stl',
  'blk',
  'tov',
  'pf',
  'fga',
  'fgm',
  'fta',
  'ftm',
  'threepa',
  'threepm'
];

export const advancedStats = [
  'ortg',
  'drtg',
  'fgPerc',
  'efgPerc',
  'tovPerc',
  'threePerc',
  'threepAR',
  'usageRate',
  'astToRatio',
  'PER',
  'pace',
  'gameScore'
];
